/*eslint-disable */

import {bus} from '@/main';
import countries from '@/services/country.json';
import { mapGetters } from 'vuex';
import pastAppointmentList from '@/components/private/appointments/past-appointment-list/';
import patientMessages from '@/components/private/patient-messages/';
import requestedAppointmentList from '@/components/private/appointments/requested-appointment-list/';
import upcomingAppointmentList from '@/components/private/appointments/upcoming-appointment-list/';

export default {
  name: 'dashboard-main',
  components: {
    'patient-messages' : patientMessages,
    'upcoming-appointments' : upcomingAppointmentList,
    'requested-appointments' : requestedAppointmentList,
    'past-appointments' : pastAppointmentList
  },
  props: [],
  data () {
    return {
      selfUserDetailData:null,
      countryList:[],
      dashboardTab:'upAppointment'
    }
  },
  computed: {
    ...mapGetters([
      'selfUserDetailGetters'
    ]),
    getSelfUserInfoMethod() {
      return this.selfUserDetailData = this.$store.getters.selfUserDetailGetters.data
    },
  },
  mounted () {
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key],
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
    this.getSelfUserInfoMethod  
  },
  methods: {
    dashboardTabChangeMethod(option){
      this.dashboardTab = option
    },
    getCountryName(countryCode) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        country = country.name
      } else {
        country = ''
      }
      return country;
    },
  },
  watch : {
    getSelfUserInfoMethod() {
      return this.$store.getters.selfUserDetailGetters
    },
  }
}